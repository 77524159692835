
import { defineComponent } from "vue";
import { TalkroomListType } from "./talkroom";
import TalkroomListItem from "./ListItem.vue";

export default defineComponent({
  name: "TalkroomList",
  components: {
    TalkroomListItem,
  },
  props: {
    talkrooms: {
      type: Object as () => TalkroomListType,
      required: true,
    },
  },
});


import { defineComponent } from "vue";
import CloseIcon from "icons/Close.vue";
import ChevronLeftIcon from "icons/ChevronLeft.vue";

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue", "close", "submit"],
  components: { CloseIcon, ChevronLeftIcon },
  methods: {
    change(e: InputEvent) {
      this.$emit("update:modelValue", (e.target as HTMLInputElement).value);
    },
    clear(e: MouseEvent) {
      this.$emit("update:modelValue", (e.target as HTMLButtonElement).value);
    },
  },
});

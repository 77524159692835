import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TalkroomListItem = _resolveComponent("TalkroomListItem")!

  return (_openBlock(), _createElementBlock("ul", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.talkrooms, (talkroom) => {
      return (_openBlock(), _createBlock(_component_TalkroomListItem, {
        talkroom: talkroom,
        key: 'talkroom-' + talkroom.id
      }, null, 8, ["talkroom"]))
    }), 128))
  ]))
}
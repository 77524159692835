import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30c72a0d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search" }
const _hoisted_2 = { class: "searchbox" }
const _hoisted_3 = { class: "input-wrap" }
const _hoisted_4 = ["value", "placeholder"]
const _hoisted_5 = { class: "right-button-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronLeftIcon = _resolveComponent("ChevronLeftIcon")!
  const _component_CloseIcon = _resolveComponent("CloseIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
    }, [
      _createVNode(_component_ChevronLeftIcon)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("input", {
          type: "text",
          value: _ctx.modelValue,
          placeholder: _ctx.placeholder,
          onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.change($event))),
          onKeypress: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.$emit('submit')), ["enter"]))
        }, null, 40, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.modelValue)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.clear && _ctx.clear(...args)))
            }, [
              _createVNode(_component_CloseIcon, { size: 23 })
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}